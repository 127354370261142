// src/pages/AnotherPage.js

import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * AnotherPage component renders a container with top margin and padding,
 * and centers its content.
 *
 * @component
 * @returns {JSX.Element} A React component that renders a styled container.
 */
const AnotherPage = () => {
  return (
    <Container className="mt-5 pt-4 text-center">
    </Container>
  );
};

export default AnotherPage;