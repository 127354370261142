// src/pages/AnotherPage.js

import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AnotherPage = () => {
  return (
    <Container className="mt-5 pt-4 text-center">
    </Container>
  );
};

export default AnotherPage;