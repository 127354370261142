// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navbar.css */

.navbar-style {
background-color: transparent;
}

.nav-link {
    color: white !important;
    margin-right: 20px;
    transition: background-color 0.3s, padding 0.3s, border-radius 0.3s;
    font-size: 1.1rem; /* Increases font size */
    padding: 12px 24px; /* Increases padding for bigger buttons */
    display: inline-block; /* Ensures padding affects the button size properly */
}

.nav-link:last-child {
    margin-right: 0; /* Remove right margin for the last link */
}

.nav-link.active {
    background-color: #FF8A00;
    padding: 12px 24px; /* Consistent padding for active state */
    border-radius: 10px;
    font-size: 1.1rem; /* Ensures active state text size matches */
    color: white; /* Ensures active state text remains white */
}
/* Optional: Add hover effect for better UX */
.nav-link:hover {
    background-color: rgba(208, 123, 0, 0.8); /* Slightly transparent on hover */
    color: white; /* Maintains white text on hover */
    border-radius: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .nav-link {
    font-size: 1rem; /* Slightly smaller font on smaller screens */
    padding: 10px 20px; /* Adjust padding */
    margin-right: 15px; /* Adjust margin */
  }

  .nav-link.active {
    padding: 10px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NavbarComponent.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;AACA,6BAA6B;AAC7B;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,mEAAmE;IACnE,iBAAiB,EAAE,wBAAwB;IAC3C,kBAAkB,EAAE,yCAAyC;IAC7D,qBAAqB,EAAE,qDAAqD;AAChF;;AAEA;IACI,eAAe,EAAE,0CAA0C;AAC/D;;AAEA;IACI,yBAAyB;IACzB,kBAAkB,EAAE,wCAAwC;IAC5D,mBAAmB;IACnB,iBAAiB,EAAE,2CAA2C;IAC9D,YAAY,EAAE,4CAA4C;AAC9D;AACA,6CAA6C;AAC7C;IACI,wCAAwC,EAAE,kCAAkC;IAC5E,YAAY,EAAE,kCAAkC;IAChD,mBAAmB;AACvB;;AAEA,2BAA2B;AAC3B;EACE;IACE,eAAe,EAAE,6CAA6C;IAC9D,kBAAkB,EAAE,mBAAmB;IACvC,kBAAkB,EAAE,kBAAkB;EACxC;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":["/* Navbar.css */\n\n.navbar-style {\nbackground-color: transparent;\n}\n\n.nav-link {\n    color: white !important;\n    margin-right: 20px;\n    transition: background-color 0.3s, padding 0.3s, border-radius 0.3s;\n    font-size: 1.1rem; /* Increases font size */\n    padding: 12px 24px; /* Increases padding for bigger buttons */\n    display: inline-block; /* Ensures padding affects the button size properly */\n}\n\n.nav-link:last-child {\n    margin-right: 0; /* Remove right margin for the last link */\n}\n\n.nav-link.active {\n    background-color: #FF8A00;\n    padding: 12px 24px; /* Consistent padding for active state */\n    border-radius: 10px;\n    font-size: 1.1rem; /* Ensures active state text size matches */\n    color: white; /* Ensures active state text remains white */\n}\n/* Optional: Add hover effect for better UX */\n.nav-link:hover {\n    background-color: rgba(208, 123, 0, 0.8); /* Slightly transparent on hover */\n    color: white; /* Maintains white text on hover */\n    border-radius: 10px;\n}\n\n/* Responsive adjustments */\n@media (max-width: 768px) {\n  .nav-link {\n    font-size: 1rem; /* Slightly smaller font on smaller screens */\n    padding: 10px 20px; /* Adjust padding */\n    margin-right: 15px; /* Adjust margin */\n  }\n\n  .nav-link.active {\n    padding: 10px 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
